import View from "@/views/Log.vue";
import Index from "@/components/logs/List.vue";
import Show from "@/components/logs/Show.vue";
import store from "@/store";

export default [
	{
		path: "/logs",
		name: "logs",
		component: View,
		redirect: {
			name: "settings-access-logs"
		},
		children: [
			{
				path: "list",
				name: "settings-access-logs",
				meta: {
					icon: "mdi-math-log"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
			},
			{
				meta: {
					namespace: "log",
					title: "Logs",
					store: "logs",
					icon: "mdi-math-log"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				path: "show/:id",
				name: "settings-logs-show",
				components: {
					page: Show
				}
			}
		]
	}
];
