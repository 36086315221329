<template>
  <form-wizard
      v-if="item"
      :form-data="formData"
      :item="item"
      :namespace="namespace"
      :title="title"
      :icon="icon"
      :store="store"
      :store-action="storeAction"
  ></form-wizard>
</template>
<script>
export default {
  name: "FileTypeEdit",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {}
    };
  },
  created() {
    const _this = this;
    _this.$store
        .dispatch(_this.store + "/getItem", _this.$route.params.id)
        .then(() => {
          _this.item = _this.$store.getters[_this.store + "/item"];
        });
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      let form = [
        {
          text: "File Type Name*",
          value: "name",
          flex: "col-sm-12 col-md-6",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "File Type Display Name*",
          value: "display_name",
          flex: "col-sm-12 col-md-6",
          required: "required",
          class: "",
          validate: "required"
        }
      ];
      return form;
    }
  }
};
</script>
