import View from "@/views/Employee.vue";
import Index from "@/components/employees/List.vue";
import Create from "@/components/employees/Create.vue";
import Edit from "@/components/employees/Edit.vue";
import Show from "@/components/employees/Show.vue";
import store from "@/store";

export default [
	{
		path: "/employee",
		name: "employee",
		component: View,
		title: "Employee",
		redirect: { name: "list-employee" },
		meta: {
			icon: "mdi-account-hard-hat",
			textColor: "light-blue--text text--darken-1",
			color: "light-blue darken-1",
			colorHex:"#039be5"
		},
		children: [
			{
				path: "list",
				name: "list-employee",
				meta: {
					icon: "mdi-account-hard-hat",
					textColor: "light-blue--text text--darken-1",
					color: "light-blue darken-1"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", { dialog: false });
					next();
				},
				components: {
					page: Index
				}
			},
			{
				path: "create",
				name: "create-employee",
				meta: {
					storeAction: "employees/insertItem",
					namespace: "employee",
					textColor: "light-blue--text text--darken-1",
					color: "light-blue darken-1",
					colorHex: "#039be5",
					store: "employees",
					title: "Create Employee",
					icon: "mdi-account-hard-hat"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", { dialog: true });
					next();
				},
				components: {
					modal: Create
				}
			},
			{
				path: "edit/:id",
				name: "edit-employee",
				meta: {
					storeAction: "employees/updateItem",
					namespace: "employee",
					textColor: "light-blue--text text--darken-1",
					color: "light-blue darken-1",
					colorHex: "#039be5",
					store: "employees",
					title: "Edit Employee",
					icon: "mdi-account-hard-hat"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", { dialog: true });
					next();
				},
				components: {
					modal: Edit
				}
			},
			{
				path: "show/:id",
				meta: {
					namespace: "employee",
					title: "Employee",
					store: "employees",
					textColor: "light-blue--text text--darken-1",
					color: "light-blue darken-1",
					colorHex: "#039be5",
					icon: "mdi-account-hard-hat"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				name: "show-employee",
				components: {
					page: Show
				}
			}
		]
	}
];
