<template>
  <form-wizard
    :form-data="formData"
    :item="item"
    :namespace="namespace"
    :title="title"
    :icon="icon"
    :store="store"
    :store-action="storeAction"
  ></form-wizard>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "UserCreate",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {}
    };
  },
  async mounted() {
    await this.$store.dispatch("roles/getItems", { all: true });
  },
  computed: {
    ...mapGetters({
      roles:"roles/items"
    }),
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      let form = [
        {
          text: "Full Name*",
          value: "name",
          flex: "col-sm-12 col-md-12",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Email*",
          value: "email",
          flex: "col-sm-12 col-md-6",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Password*",
          value: "password",
          password: true,
          generator: true,
          flex: "col-sm-12 col-md-6",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Role*",
          value: "roles",
          flex: "col-sm-12 col-md-12",
          required: "required",
          multiple: true,
          chips: true,
          options: this.roles,
          class: "",
          validate: "required"
        }
      ];
      return form;
    }
  }
};
</script>
