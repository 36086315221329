<template>
  <simple-list
    :names="names"
    :icon="icon"
    :title="title"
    :route-name="routeName"
    :key-title="keyTitle"
    :namespace="namespace"
  ></simple-list>
</template>
<script>
export default {
  name: "UserList",
  components: {
    SimpleList: () => import("@/components/core/lists/SimpleList")
  },
  data() {
    return {
      routeName: "users",
      namespace: "users",
      title: "Users",
      keyTitle: "name",
      names: [
        {
          text: "Name",
          value: "name",
          sortable: true
        },
        {
          text: "Email",
          value: "email",
          sortable: true
        },
      ]
    };
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    }
  },
  created() {}
};
</script>
