import ProductivityApi from "@/api/productivity";
import ErrorHandling from "@/plugins/ErrorHandling";
const errorHandling = new ErrorHandling();
const restApi = new ProductivityApi();

const getItems = async ({ commit, state }) => {
    try {
        commit("setLoading", true);
        const options = JSON.parse(JSON.stringify(state.pagination));
        Object.keys(options).forEach(function () {
            options["perPage"] = options["rowsPerPage"];
            options["orderDir"] = options["sortDesc"] ? "desc" : "asc";
        });
        const items = await restApi.getList(options);
        commit("setItemsData", items.data);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const setPagination = async ({ commit }, payload) => {
    commit("setPagination", payload);
};
const getDetails = async ({ commit, state }, itemId, options) => {
    try {
        commit("setLoading", true);
        const options = JSON.parse(JSON.stringify(state.detailsPagination));
        Object.keys(options).forEach(function () {
            options["perPage"] = options["rowsPerPage"];
            options["orderDir"] = options["sortDesc"] ? "desc" : "asc";
        });
        const items = await restApi.getDetails(itemId, options);
        commit("setDetailsData", items.data);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};
const setDetailsPagination = async ({ commit }, payload) => {
    commit("setDetailsPagination", payload);
};
const getItem = async ({ commit }, itemId) => {
    try {
        commit("setLoading", true);
        const item = await restApi.getItem(itemId);
        commit("setItemData", item);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const insertItem = async ({ commit }, item) => {
    try {
        commit("setLoading", true);
        await restApi.insertItem(item);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const updateItem = async ({ commit }, item) => {
    try {
        commit("setLoading", true);
        await restApi.updateItem(item.id, item);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const deleteItem = async ({ commit }, item) => {
    try {
        commit("setLoading", true);
        await restApi.deleteItem(item);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};
const importProductivity = async ({ commit }, item) => {
    try {
        commit("setLoading", true);
        await restApi.importProductivity(item);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};
const exportAllItemsGet = async ({ commit,state }) => {
    try {
        const options = JSON.parse(JSON.stringify(state.pagination));
        const items = await restApi.getList({ all: true,...options });
        commit("exportItems", items);
    } catch (e) {
        errorHandling.onError(e);

    }
};
const exportDetailsList = async ({ commit,state }, itemId) => {
    try {
        const options = JSON.parse(JSON.stringify(state.detailsPagination));
        const items = await restApi.getDetails(itemId, { all: true,...options })
        commit('exportDetailsItems', items)
    } catch (e) {
        errorHandling.onError(e)

    }
}
export default {
    getItems,
    getItem,
    setPagination,
    insertItem,
    updateItem,
    deleteItem,
    importProductivity,
    getDetails,
    setDetailsPagination,
    exportAllItemsGet,
    exportDetailsList
};
