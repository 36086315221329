import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import moment from "moment";

const state = {
    loading: false,
    totalEmployees: [],
    totalCost: [],
    overTime: [],
    sickLeave: [],
    contractTermination: [],
  /*  totalEmployeesCompare: [],
    totalCostCompare: [],
    overTimeCompare: [],
    sickLeaveCompare: [],
    contractTerminationCompare: [],*/
    selected: null,
    chartData: null,
    chartRawData: null,
    filter: {
    /*  date_from: moment()
        .subtract(1, 'months')
        .format("YYYY-MM"),*/
        date_from:null,
      date_to: null,
      date_from_compare: null,
      date_to_compare: null,
      report_type: "by_date",
    },
    cardData: [
      {
        name: "employees",
        titleNumber: 0,
        subtitle: "Employees",
        active: true,
        leftBoxTitle: "150",
        leftBoxSubtitle: "2021",
        dispatch: "totalEmployees",
        keyFilter: "number",
        rightBox: "0"
      },
      {
        titleNumber: 0,
        name: "total-cost",
        addTitleCaption: "RSD",
        subtitle: "Total Cost",
        active: false,
        leftBoxTitle: "23423",
        leftBoxSubtitle: "2021",
        dispatch: "totalCost",
        keyFilter: "cost",
        rightBox: "0"
      },
      {
        titleNumber: "22",
        name: "contract-terminated",
        subtitle: "Contract terminated",
        active: false,
        leftBoxTitle: "23",
        leftBoxSubtitle: "2021",
        dispatch: "contractTermination",
        keyFilter: "number",
        rightBox: "0"
      },
      {
        titleNumber: 0,
        name: "overtime",
        addTitleCaption: "h",
        subtitle: "Overtime hours",
        active: false,
        leftBoxTitle: "322",
        dispatch: "overTime",
        keyFilter: "overtime",
        leftBoxSubtitle: "2021",
        rightBox: "0"
      },
      {
        titleNumber: 0,
        addTitleCaption: "h",
        name: "sick-leave",
        subtitle: "Sick leave",
        active: false,
        leftBoxTitle: "300",
        dispatch: "sickLeave",
        keyFilter: "sick_leave",
        leftBoxSubtitle: "2021",
        rightBox: "0"
      }
    ],
    pagination: {
        page: 1,
        orderBy: "name",
        mustSort: true,
        descending: true,
        sortDesc: true,
        search: "",
        rowsPerPage: 12,
        loadRows: 12
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
