import Vue from "vue";
import _ from "lodash";
import dashboardHelper from "@/plugins/Helpers/dashboardHelper";
import moment from "moment";

const setLoading = (state, value) => {
    state.loading = value;
};
const setItemsData = (state, value) => {
    state.totalEmployees = value?.data ? value.data : value;
};
const setTotalCost = (state, value) => {
    state.totalCost = value?.data ? value.data : value;
}
const setContractTermination = (state, value) => {
    state.contractTermination = value?.data ? value.data : value;
}
const setOverTime = (state, value) => {
    state.overTime = value?.data ? value.data : value;
}
const setSickLeave = (state, value) => {
    state.sickLeave = value?.data ? value.data : value;
}
/*
const setItemsDataCompare = (state, value) => {
    state.totalEmployeesCompare = value?.data ? value.data : value;
};
const setTotalCostCompare = (state, value) => {
    state.totalCostCompare = value?.data ? value.data : value;
}
const setContractTerminationCompare = (state, value) => {
    state.contractTerminationCompare = value?.data ? value.data : value;
}
const setOverTimeCompare = (state, value) => {
    state.overTimeCompare = value?.data ? value.data : value;
}
const setSickLeaveCompare = (state, value) => {
    state.sickLeaveCompare = value?.data ? value.data : value;
}*/
const setChartData = (state) => {
    state.chartRawData = state[state.selected.dispatch]
    state.chartData = dashboardHelper.setLabels({ array: state[state.selected.dispatch], key: state.selected.keyFilter }, state.filter)
}
const setPagination = (state, value) => {
    Vue.set(state, "pagination", Object.assign(state.pagination, value));
}

const setSelected = (state, value) => {
    state.selected = (value) ? value : state.cardData[0];
}
/*
const setTotalsCompare = (state, value) => {
  let previousYearDate = moment(state.filter.date_from).subtract(1, 'years').format("YYYY")
  state.cardData[0].leftBoxTitle = _.round(
    _.meanBy(dashboardHelper.getFilteredArray({array:state.totalEmployeesCompare,key:"employees"},state.filter), p => p.number)
  );
  state.cardData[1].leftBoxTitle = _.sumBy(state.totalCostCompare, 'cost').toFixed(2);
   state.cardData[2].leftBoxTitle = _.sumBy(state.contractTerminationCompare, 'number')
   state.cardData[3].leftBoxTitle = _.sumBy(state.overTimeCompare, "overtime");
   state.cardData[4].leftBoxTitle = _.sumBy(state.sickLeaveCompare, "sick_leave");
  state.cardData[0].leftBoxSubtitle = previousYearDate
  state.cardData[1].leftBoxSubtitle = previousYearDate
  state.cardData[2].leftBoxSubtitle = previousYearDate
  state.cardData[3].leftBoxSubtitle = previousYearDate
  state.cardData[4].leftBoxSubtitle = previousYearDate
  state.cardData[0].rightBox = dashboardHelper.getPercentageIncrease(state.cardData[0].titleNumber, state.cardData[0].leftBoxTitle).toFixed(2)
  state.cardData[1].rightBox = dashboardHelper.getPercentageIncrease(state.cardData[1].titleNumber, state.cardData[1].leftBoxTitle).toFixed(2)
  state.cardData[2].rightBox = dashboardHelper.getPercentageIncrease(state.cardData[2].titleNumber, state.cardData[2].leftBoxTitle).toFixed(2)
  state.cardData[3].rightBox = dashboardHelper.getPercentageIncrease(state.cardData[3].titleNumber, state.cardData[3].leftBoxTitle).toFixed(2)
  state.cardData[4].rightBox = dashboardHelper.getPercentageIncrease(state.cardData[4].titleNumber, state.cardData[4].leftBoxTitle).toFixed(2)
}*/

const setTotals = (state, value) => {
    state.cardData[0].titleNumber = _.round(_.sumBy(state.totalEmployees, (p) => p.number));
    state.cardData[1].titleNumber = _.sumBy(state.totalCost, 'cost').toFixed(2);
    state.cardData[2].titleNumber = _.sumBy(state.contractTermination, 'number')
    state.cardData[3].titleNumber = _.sumBy(state.overTime, "overtime");
    state.cardData[4].titleNumber = _.sumBy(state.sickLeave, "sick_leave");
}

export default {
    setPagination,
    setLoading,
    setItemsData,
    setTotalCost,
    setOverTime,
    setSickLeave,
    setContractTermination,
    /*setItemsDataCompare,
    setTotalCostCompare,
    setOverTimeCompare,
    setSickLeaveCompare,
    setContractTerminationCompare,*/
    setTotals,
    //  setTotalsCompare,
    setSelected,
    setChartData
};
