const items = state => state.items;
const total = state => state.total;
const loading = state => state.loading;
const loadingNew = state => state.loadingNew;
const newNotifications = state => state.newNotifications;
const newNotificationsBadge = state => state.newNotificationsBadge;
const pagination = state => state.pagination;
const	snackOptions= state => state.snackOptions;

export default {
	items,
	total,
	loading,
	loadingNew,
	newNotifications,
	newNotificationsBadge,
	snackOptions,
	pagination
};
