import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
	items: [],
	total: 0,
	item: {},
	loading: false,
	exportItems:[],
	pagination: {
		page: 1,
		orderBy: "environment",
		mustSort: true,
		descending: true,
		sortDesc: true,
		search: "",
		rowsPerPage: 12,
		loadRows: 12
	}
};

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations
};
