<template>
  <simple-list
    :names="names"
    :icon="icon"
    :title="title"
    :show="show"
    :route-name="routeName"
    :key-title="keyTitle"
    :namespace="namespace"
  ></simple-list>
</template>
<script>
export default {
  name: "LogsList",
  components: {
    SimpleList: () => import("@/components/core/lists/SimpleList")
  },
  data() {
    return {
      routeName: "settings-logs",
      namespace: "logs",
      title: "Logs",
      show: true,
      keyTitle: "level",
      names: [
        {
          text: "Environment",
          value: "environment",
          sortable: true
        },
        {
          text: "Level",
          value: "level",
          sortable: true
        },
        {
          text: "Message",
          value: "context.message",
          sortable: false
        },
        {
          text: "Date",
          value: "date",
          sortable: true
        }
      ]
    };
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    }
  },
  created() {}
};
</script>
