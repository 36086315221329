import RestApi from "@/api/RestApi";

export default class ProductivityApi extends RestApi {
    constructor() {
        super();
        this.baseEndpoint = this.domain + "productivity/";
    }

    async getList(pagination) {
        let url = this.baseEndpoint;
        let response = await this.getData(url, pagination);
        return response;
    }

    async getItem(id) {
        let url = this.baseEndpoint + id + '/edit';
        let response = await this.getData(url);
        return response;
    }
    async getDetails(id,pagination) {
        let url = this.baseEndpoint + id + '/details';
        let response = await this.getData(url,pagination);
        return response;
    }

    async insertItem(item) {
        let url = this.baseEndpoint;
        let response = await this.insertData(url, item);
        return response;
    }

    async updateItem(id, item) {
        let url = this.baseEndpoint + id;
        let response = await this.putData(url, item);
        return response;
    }

    async deleteItem(id) {
        let url = this.baseEndpoint + id;
        let response = await this.deleteData(url);
        return response;
    }

    async importProductivity(item) {
        let url = this.baseEndpoint + 'import';
        let config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        let response = await this.insertData(url, item,config);
        return response
    }
}
