import Vue from "vue";
import _ from "lodash";
import moment from "moment";
export default {

  helperFunction(objData, filter, filterName) {
    let collection = {};
    let dataset = [];
    let labels = [];
    let dateRange = this.calculateDateRange(filter)

    let groupedArray = _.groupBy(objData.array, filterName);
    let groupedByDate = _.groupBy(objData.array, "for_month");


    labels = _.keys(groupedByDate);
    labels = _.union(labels, dateRange).sort();
    _.forEach(groupedArray, (employee, key) => {
      let dataForChart = [];
      for (let i = 0; i < labels.length; i++) {
        _.some(employee, ["for_month", labels[i]])
          ? dataForChart.push(
              _.find(employee, ["for_month", labels[i]])[objData.key]
            )
          : dataForChart.push(0);
      }
      dataset.push({
        label: key,
        data: dataForChart,
        fill: false,
        borderColor:
          "#" +
          (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6),
        backgroundColor:
          "#" +
          (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6)
      });
    });

    labels = labels.map(val => moment(val).format("MMMM YYYY"));
    collection = {
      labels: labels,
      datasets: dataset
    };
    return collection;
  },

  getDateRange(startDate, endDate, returnAsObjectArray) {
    let start = (startDate) ? startDate.split("-") : new Array(moment()
        .format("YYYY"));
    let end = endDate.split("-");
    let startYear = parseInt(start[0]) ;
    let endYear = parseInt(end[0]);
    let dates = [];

    for (let i = startYear; i <= endYear; i++) {
      let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      let startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (
        let j = startMon;
        j <= endMonth;
        j = j > 12 ? j % 12 || 11 : j + 1
      ) {
        let month = j + 1;
        let displayMonth = month < 10 ? "0" + month : month;
        if (returnAsObjectArray) {
          dates.push({
            for_month: [i, displayMonth, "01"].join("-"),
            number: 0
          });
        } else {
          dates.push([i, displayMonth, "01"].join("-"));
        }
      }
    }
    return dates;
  },

  calculateDateRange(filter,returnAsObjectArray=false){
    let currentDate = filter.date_to
      ? filter.date_to
      : new Date();
    let dateRange = this.getDateRange(
      filter.date_from,
      moment(currentDate).format("YYYY-MM-DD"),
      returnAsObjectArray
    );
    return dateRange
  },
  getFilteredArray(objectData,filter) {
    let array = objectData.array;
    let dateRange = this.calculateDateRange(filter,true)
    let newArray = _.unionBy(
      JSON.parse(JSON.stringify(array)),
      dateRange,
      "for_month"
    );
    return _.orderBy(newArray, ["for_month"], ["asc"]);
  },

 getPercentageIncrease(numA, numB) {
  if(numB===0) return 100
  return ((numA - numB) / numB) * 100;
},

  setLabels(objectData,filter) {
    let collection = {};
    let dataset = [];
    let labels = [];
    let chartData = null;
    switch (filter.report_type) {
      case "by_job_position":
        {
    
          chartData = this.helperFunction(
            objectData,
            filter,
            "job_position_name"
          );
        }
        return chartData
      case "by_date":
        {
          let newArray = this.getFilteredArray(objectData,filter);
          newArray.forEach(item => {
            labels.push(moment(item.for_month).format("MMMM YYYY"));
            dataset.push(item[objectData.key]||0);
          });
          collection = {
            labels: labels,
            datasets: [
              {
                label: "Employees",
                backgroundColor:
                  "#" +
                  (0x1000000 + Math.random() * 0xffffff)
                    .toString(16)
                    .substr(1, 6),
                data: dataset
              }
            ]
          };
        chartData = collection;
        }
        return chartData
      case "by_job_location":
        {
          chartData = this.helperFunction(objectData,
          filter,"job_location");
        }
        return chartData
      case "by_job_location_city":
        {
          chartData = this.helperFunction(
            objectData,
            filter,
            "job_location_city"
          );
        }
        return chartData
    }
  }

};
