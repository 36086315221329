const items = state => state.items;
const total = state => state.total;
const item = state => state.item;
const loading = state => state.loading;
const pagination = state => state.pagination;
const exportItems = state => state.exportItems;

export default {
    items,
    total,
    item,
    loading,
    exportItems,
    pagination
};
