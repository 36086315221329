import View from "@/views/Productivity.vue";
import Index from "@/components/productivity/List.vue";
import Create from "@/components/productivity/Create.vue";
import store from "@/store";

export default [
    {
        path: "/productivity",
        name: "productivity",
        component: View,
        title: "Productivity",
        store:"productivity",
        redirect: {
            name: "list-productivity"
        },
        children: [
            {
                path: "list",
                name: "list-productivity",
                meta: {
                    icon: "mdi-finance"
                },
                beforeEnter: (to, from, next) => {
                    store.commit("options/setOptionsData", {
                        dialog: false
                    });
                    next();
                },
                components: {
                    page: Index
                }
            },
            {
                path: "create",
                name: "create-productivity",
                meta: {
                    storeAction: "productivity/importProductivity",
                    namespace: "productivity",
                    store: "productivity",
                    title: "Create Productivity",
                    icon: "mdi-finance"
                },
                beforeEnter: (to, from, next) => {
                    store.commit("options/setOptionsData", {
                        dialog: true
                    });
                    next();
                },
                components: {
                    modal: Create
                }
            },
        ]
    }];
