import Vue from "vue";
const setItemsData = (state, value) => {
	state.items = value.data ? value.data : value;
	state.total = value.total ? value.total : state.total;
};

const setProductivityDetails = (state,value)=>{
	state.productivityDetails = value.data ? value.data : value;
	state.totalDetails = value.total;
};

const setExpiredContractItems = (state,value)=>{
	state.expiredContracts = value;
};

const setSevenDaysContracts = (state,value)=>{
	state.sevenDaysContracts = value
};
const setAvailableData = (state, value) => {
	state.available = value;
};
const setJobLocations = (state, value) => {
	state.jobLocations = value;
};
const setJobPositions = (state, value) => {
	state.jobPositions = value;
};

const setEmptyItems = state => {
	state.items = [];
};

const setTerminationReasonsData = (state, value) => {
	state.terminationReasons = value;
};

const setItemData = (state, value) => {
	state.item = value.data ? value.data : state.item;
};

const setLoading = (state, value) => {
	state.loading = value;
};

const setPagination = (state, value) => {
	state.total = 0;
	Vue.set(state, "pagination", Object.assign(state.pagination, value));
};
const exportItems = (state,value)=>{
	state.exportItems = value.data ? value.data : value;
}

export default {
	setItemsData,
	setEmptyItems,
	setAvailableData,
	setTerminationReasonsData,
	setItemData,
	setPagination,
	setProductivityDetails,
	setJobLocations,
	setJobPositions,
	setLoading,
	setExpiredContractItems,
	setSevenDaysContracts,
	exportItems
};
