import Vue from "vue";

const setItemsData = (state, value) => {
	state.items = value.data ? value.data : value;
	state.total = value.total ? value.total : state.total;
};
const setDetailsData = (state, value) => {
	state.details = value.data ? value.data : value;
	state.detailsTotal = value.total ? value.total : state.total;
};

const setItemData = (state, value) => {
	state.item = value.data ? value.data : state.item;
};

const setLoading = (state, value) => {
	state.loading = value;
};

const setSelectedHeaders = (state, value) => {
	state.selectedHeaders = value;
	localStorage.setItem("selectedHeaders", JSON.stringify(value));
};

const setPagination = (state, value) => {
	Vue.set(state, "pagination", Object.assign(state.pagination, value));
};
const setDetailsPagination = (state, value) => {
	Vue.set(
		state,
		"detailsPagination",
		Object.assign(state.detailsPagination, value)
	);
};
const exportItems = (state,value)=>{
	state.exportItems = value.data ? value.data : value;
};
const exportDetailsItems = (state,value)=>{
	Vue.set(state, "exportDetailsItems", value);
}

export default {
	setItemsData,
	setItemData,
	setPagination,
	setSelectedHeaders,
	setLoading,
	setDetailsData,
	setDetailsPagination,
	exportItems,
	exportDetailsItems
};
