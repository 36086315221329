import EmployeesApi from "@/api/employees";
import ErrorHandling from "@/plugins/ErrorHandling";

const errorHandling = new ErrorHandling();
const restApi = new EmployeesApi();

const getItems = async ({commit, state}, all = false) => {
    try {
        commit("setLoading", true);
        commit("setEmptyItems");
        const options = JSON.parse(JSON.stringify(state.pagination));
        Object.keys(options).forEach(function () {
            options["perPage"] = options["rowsPerPage"];
            options["orderDir"] = options["sortDesc"] ? "desc" : "asc";
        });
        const items = await restApi.getList(all ? all : options);
        commit("setItemsData", items);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
        commit("setLoading", false);
    }
};
const getExpiredContractEmployees = async ({commit}, options) => {
    try {
        commit("setLoading", true);
        Object.keys(options).forEach(function () {
            options["perPage"] = options["rowsPerPage"];
            options["orderDir"] = options["sortDesc"] ? "desc" : "asc";
        });
        const items = await restApi.getList(options);
        commit("setExpiredContractItems", items);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};
const getSevenDaysContracts = async ({commit}, options) => {
    try {
        commit("setLoading", true);
        Object.keys(options).forEach(function () {
            options["perPage"] = options["rowsPerPage"];
            options["orderDir"] = options["sortDesc"] ? "desc" : "asc";
        });
        const items = await restApi.getList(options);
        commit("setSevenDaysContracts", items);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const getProductivityDetails = async ({commit, state}, id) => {
    try {
      const options = JSON.parse(JSON.stringify(state.pagination));
      Object.keys(options).forEach(function () {
          options["perPage"] = options["rowsPerPage"];
          options["orderDir"] = options["sortDesc"] ? "desc" : "asc";
      });
      const items = await restApi.getProductivityDetails(options,id);
        commit("setProductivityDetails", items.data);
        commit("setLoading", false);
    } catch (error) {
        commit("setLoading", false);
        errorHandling.onError(error);
    }
};

const assignEmployee = async ({commit, dispatch}, item) => {
    try {
        commit("setLoading", true);
        await restApi.assignEmployee(item);
        dispatch("availableEmployees", {all: true});
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const unassignEmployee = async ({commit, dispatch}, item) => {
    try {
        commit("setLoading", true);
        await restApi.unassignEmployee(item);
        dispatch("availableEmployees", {all: true});
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const terminateEmployee = async ({commit, dispatch}, item) => {
    try {
        commit("setLoading", true);
        await restApi.terminateEmployee(item);
        //dispatch("availableEmployees", { all: true });
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const jobPositions = async ({commit}) => {
    try {
        commit("setLoading", true);
        const items = await restApi.jobPositions();
        commit("setJobPositions", items);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const jobLocations = async ({commit}) => {
    try {
        commit("setLoading", true);
        const items = await restApi.jobLocations();
        commit("setJobLocations", items);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const availableEmployees = async ({commit}, item) => {
    try {
        commit("setLoading", true);
        const items = await restApi.availableEmployees();
        commit("setAvailableData", items);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const terminationReasons = async ({commit}) => {
    try {
        commit("setLoading", true);
        const items = await restApi.terminationReasons();
        commit("setTerminationReasonsData", items);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const setPagination = async ({commit}, payload) => {
    commit("setPagination", payload);
};

const getItem = async ({commit}, itemId) => {
    try {
        commit("setLoading", true);
        const item = await restApi.getItem(itemId);
        commit("setItemData", item);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const showItem = async ({commit, state}, itemId) => {
    try {
        commit("setLoading", true);
        const options = JSON.parse(JSON.stringify(state.pagination));
        const item = await restApi.showItem(itemId, options);
        commit("setItemData", item);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const insertItem = async ({commit}, item) => {
    try {
        commit("setLoading", true);
        await restApi.insertItem(item);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const updateItem = async ({commit}, item) => {
    try {
        commit("setLoading", true);
        await restApi.updateItem(item.id, item);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const deleteItem = async ({commit}, item) => {
    try {
        commit("setLoading", true);
        await restApi.deleteItem(item);
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error);
    }
};

const exportAllItemsGet = async ({commit,state}) => {
    try {
        const options = JSON.parse(JSON.stringify(state.pagination));
        const items = await restApi.getList({all: true,...options});
        commit("exportItems", items);
    } catch (e) {
        errorHandling.onError(e);

    }
}

export default {
    getItems,
    getItem,
    showItem,
    assignEmployee,
    unassignEmployee,
    terminateEmployee,
    terminationReasons,
    availableEmployees,
    jobPositions,
    jobLocations,
    setPagination,
    getProductivityDetails,
    insertItem,
    updateItem,
    deleteItem,
    getExpiredContractEmployees,
    getSevenDaysContracts,
    exportAllItemsGet
};
