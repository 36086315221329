const items = state => state.items;
const details = state => state.details;
const detailsTotal = state => state.detailsTotal;
const total = state => state.total;
const item = state => state.item;
const loading = state => state.loading;
const pagination = state => state.pagination;
const detailsPagination = state => state.detailsPagination;
const selectedHeaders = state => state.selectedHeaders;
const filters = state => state.filters;
const exportItems = state => state.exportItems;
const exportDetailsItems = state => state.exportDetailsItems;

export default {
	items,
	total,
	item,
	loading,
	pagination,
	details,
	detailsTotal,
	filters,
	selectedHeaders,
	detailsPagination,
	exportItems,
	exportDetailsItems
};
