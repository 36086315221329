import moment from "moment";

export default {
    methods: {
        /**
         * @param {date} date {string} format
         * Format Date
         */
        formatDate(date, format = "DD.MM.YYYY") {
            if (!date) return date;
            return this.$moment(date).format(format);
        },


        formDateName(date){
          return this.$moment(date).format('MMMM YYYY')
        },
        /**
         * @param {date} date
         * Humanize Date
         */
        humanizeDate(date) {
            if (!date) return date;
            let dateDiff = this.$moment().diff(date, "seconds");
            return moment.duration(-dateDiff, "seconds").humanize(true);
        },
        differenceBetweenDates(date1) {
            return this.$moment(date1).isBefore() ? "Expired " + this.$moment(moment(date1)).fromNow() : 'Expires ' + this.$moment(moment(date1)).fromNow();
        },

        checkIsAfter(date) {
            return this.$moment().isAfter(date)
        },
        checkIs7Days(date) {
            return this.$moment(date).diff(this.$moment(), 'days') < 7
        }

    }
};
