<template>
  <v-container fluid>
    <v-row justify="space-around" no-gutters>
      <v-col cols="12">
        <v-sheet color="grey lighten-5" v-if="user">
          <v-card transparent flat>
            <v-list-item class="px-5" two-line>
              <v-avatar size="170" tile>
                <v-img
                    :lazy-src="user.avatar ? user.avatar : require('@/assets/default-user-icon.jpeg')"
                    :src="user.avatar ? user.avatar : require('@/assets/default-user-icon.jpeg')"
                    alt="Users avatar">
                  <v-file-input
                      accept="image/*"
                      class="d-flex justify-center align-center pb-2 pl-3"
                      hide-input
                      label="File input"
                      prepend-icon="mdi-camera"
                      @change="uploadAvatar($event)"
                      color="green"
                  >
                  </v-file-input>
                </v-img>
              </v-avatar>
              <div class="pl-2">
                <h2 class="grey--text text--darken-1 ma-2 display-1">{{ user.name }}
                </h2>
                <v-progress-linear
                    v-if="loading"
                    color="green"
                    indeterminate
                    striped
                    height="6"
                ></v-progress-linear>
              </div>
            </v-list-item>
            <v-col cols="12" class="px-0">
              <form-wizard
                  :form-data="formData"
                  :item="item"
                  :go-to="goTo"
                  :namespace="namespace"
                  :icon="icon"
                  :store="store"
                  :store-action="storeAction"
                  create-form-data="true"
              ></form-wizard>
            </v-col>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "MyAccount",
  data() {
    return {
      activeTab: null,
      password: null,
      avatar: null,
      loading: false
    };
  },
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  computed: {
    goTo() {
      return "/user/my-account";
    },
    item() {
      const {id, name, email} = this.user;
      return {id, name, email, password: this.password};
    },
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    user() {
      return JSON.parse(this.$auth.remember()) || {};
    },
    formData() {
      let form = [
        {
          text: "Full Name*",
          value: "name",
          flex: "col-sm-12 col-md-12",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Email*",
          value: "email",
          flex: "col-sm-12 col-md-6",
          required: "required",
          class: "",
          validate: "required"
        },
        {
          text: "Password*",
          value: "password",
          password: true,
          generator: false,
          flex: "col-sm-12 col-md-6",
          required: "",
          class: "",
          validate: ""
        }
      ];
      return form;
    }
  },
  methods: {
    uploadAvatar(event) {
      this.loading = true;
      const formData = new FormData();
      formData.append("avatar", event, event.name)
      formData.append("id", this.user.id)
      formData.append('_method', "PUT")
      this.$store.dispatch('users/updateItem', formData).then(res => {
        this.$auth.fetch().then(response => {
          this.$auth.user(JSON.parse(JSON.stringify(response.data.user)));
          this.$auth.remember(JSON.stringify(response.data.user));
        });
        this.user.avatar = res.data.avatar
        this.loading = false
      })
    },
  }
};
</script>
