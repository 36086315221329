import RestApi from "@/api/RestApi";

export default class EmployeesApi extends RestApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "employees/";
	}

	async getList(pagination) {
		let url = this.baseEndpoint;
		let response = await this.getData(url, pagination);
		return response;
	}

	async getItem(id) {
		let url = this.baseEndpoint + id + "/edit";
		let response = await this.getData(url);
		return response;
	}

	async showItem(id,options) {
		let url = this.baseEndpoint + id;
		let response = await this.getData(url,options);
		return response;
	}

	async insertItem(item) {
		let url = this.baseEndpoint;
		let response = await this.insertData(url, item);
		return response;
	}

	async updateItem(id, item) {
		let url = this.baseEndpoint + id;
		let response = await this.putData(url, item);
		return response;
	}

	async getActivityLog(id) {
		let url = this.baseEndpoint + id + "/activity-log";
		let response = await this.getData(url);
		return response;
	}

	async deleteItem(id) {
		let url = this.baseEndpoint + id;
		let response = await this.deleteData(url);
		return response;
	}

	async getProductivityDetails(pagination,id) {
		let url =this.domain + "employee/" + id + "/productivity/details";
		let response = await this.getData(url,pagination);
		return response;
	}

	async assignEmployee(item) {
		let url = this.baseEndpoint + item.id + "/assign";
		let response = await this.insertData(url, item);
		return response;
	}

	async unassignEmployee(item) {
		let url = this.baseEndpoint + item.id + "/assignment-remove";
		let response = await this.insertData(url, item);
		return response;
	}

	async terminateEmployee(item) {
		let url = this.baseEndpoint + item.id + "/terminate-contract";
		let response = await this.insertData(url, item);
		return response;
	}

	async jobLocations() {
		let url = this.domain + "fetch/job_locations";
		let response = await this.getData(url);
		return response;
	}

	async jobPositions() {
		let url = this.domain + "fetch/job_positions";
		let response = await this.getData(url);
		return response;
	}
	async availableEmployees(item) {
		let url = this.baseEndpoint + "available/get";
		let response = await this.getData(url);
		return response;
	}

	async terminationReasons(item) {
		let url = this.baseEndpoint + "assignment-termination-reasons/get";
		let response = await this.getData(url);
		return response;
	}
}
