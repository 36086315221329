<template>
  <employee-simple-list
    :names="names"
    :icon="icon"
    :title="title"
    :route-name="routeName"
    :show="show"
    :go-to="goTo"
    :show-data-only="showDataOnly"
    :show-comments-button="showCommentsButton"
    :show-activity-button="showActivityButton"
    :key-title="keyTitle"
    :export-titles="exportTitles"
    :namespace="namespace"
  ></employee-simple-list>
</template>
<script>
export default {
  name: "EmployeesList",
  components: {
    EmployeeSimpleList: () => import("@/components/core/lists/EmployeeList")
  },
  props: {
    showDataOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      routeName: "employee",
      namespace: "employees",
      title: "Employees",
      showCommentsButton: true,
      showActivityButton: true,
      keyTitle: "",
      goTo: "/employee",
      show: true,
      exportTitles: [
        {
          text: "PSIT id",
          value: "psit_id"
        },
        {
          text: "First Name",
          value: "first_name"
        },
        {
          text: "Middle Name",
          value: "middle_name"
        },
        {
          text: "Last Name",
          value: "last_name"
        },
        {
          text: "Job position",
          value: "job_position_name"
        },{
          text: "Job location",
          value: "job_location"
        },
        {
          value: "first_contract_date",
          text: "First Contact date"
        },
        {
          value: "last_contract_date",
          text: "Last Contact date"
        },
        {
          value: "contract_expiration_date",
          text: "Contact Expiration date"
        },
        {
          value: "sick_leave",
          text: "Sick leave hours"
        },
        {
          value: "overtime_hours",
          text: "Overtime hours"
        },
        {
          value: "vacation_days",
          text: "Vocation days"
        },{
          value: "vacation_days_left",
          text: "Vocation days left"
        },{
          value: "vacation_days_used",
          text: "Vocation days used"
        },
        {
          text: "Bruto Salary",
          value: "gross_salary"
        },
        {
          text: "Neto Salary",
          value: "neto_salary"
        },{
          text: "Total Cost",
          value: "total_cost"
        }
      ],
      names: [
        {
          icon: "mdi-briefcase-variant",
          text: "Job position",
          value: "job_position_name",
          showInCard: true,
          sortable: false
        },
        {
          icon: "mdi-clipboard-outline",
          value: "contract_expiration_date",
          sortable: true,
          text: "Contact date",
          showInCard: true,
          date: true
        },
        {
          icon: "mdi-sigma",
          text: "Total Cost",
          value: "total_cost",
          showInCard: true,
          sortable: true
        },
        {
          icon: "",
          text: "New comments",
          showInCard: false,
          value: "new_comments_count",
          sortable: true
        }
      ]
    };
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    }
  },
};
</script>
