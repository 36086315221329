import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";
import Logout from "@/views/Logout.vue";
import Roles from "@/router/roles";
import Files from "@/router/files";
import Users from "@/router/users";
import Logs from "@/router/logs";
import AccessLogs from "@/router/accessLogs";
import Permissions from "@/router/permissions";
import FileTypes from "@/router/fileTypes";
import Employees from "@/router/employees";
import Productivity from "@/router/productivity"

Vue.use(VueRouter);

function loadView(view) {
	return () => import(`../views/${view}.vue`);
}

const routes = [
	{
		path: "/",
		name: "login",
		meta: {
			auth: false
		},
		component: Login
	},
	{
		path: "/logout",
		name: "logout",
		meta: {
			auth: true
		},
		component: Logout
	},
	{
		path: "/dashboard",
		meta: {
			auth: true
		},
		component: loadView("Wrapper"),
		children: [
			{
				path: "",
				name: "dashboard",
				meta: {
					icon: "mdi-view-dashboard-outline",
					color:'green darken-3'
				},
				component: loadView("Dashboard")
			},
			{
				path: "/notifications",
				name: "notification",
				component: loadView("Notification")
			},
			...Roles,
			...Users,
			...Logs,
			...AccessLogs,
			...FileTypes,
			...Files,
			...Employees,
			...Permissions,
			...Productivity
		]
	},
	{
		path: "*",
		redirect: {
			name: "login"
		}
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

Vue.router = router;
window.router = router;
export default router;
