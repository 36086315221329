import View from "@/views/Role.vue";
import Index from "@/components/roles/List.vue";
import Create from "@/components/roles/Create.vue";
import Edit from "@/components/roles/Edit.vue";
import store from "@/store";

export default [
    {
        path: "/role",
        name: "role",
        component: View,
        title: "Role",
        redirect: {
            name: "list-role"
        },
        children: [
            {
                path: "list",
                name: "list-role",
                meta: {
                    icon: "mdi-account-supervisor"
                },
                beforeEnter: (to, from, next) => {
                    store.commit("options/setOptionsData", {
                        dialog: false
                    });
                    next();
                },
                components: {
                    page: Index
                }
            },
            {
                path: "create",
                name: "users-roles-create",
                meta: {
                    storeAction: "roles/insertItem",
                    namespace: "role",
                    store: "roles",
                    title: "Create Role",
                    icon: "mdi-account-supervisor"
                },
                beforeEnter: (to, from, next) => {
                    store.commit("options/setOptionsData", {
                        dialog: true
                    });
                    next();
                },
                components: {
                    modal: Create
                }
            },
            {
                path: "edit/:id",
                name: "users-roles-edit",
                meta: {
                    storeAction: "roles/updateItem",
                    namespace: "role",
                    store: "roles",
                    title: "Edit role",
                    icon: "mdi-account-supervisor"
                },
                beforeEnter: (to, from, next) => {
                    store.commit("options/setOptionsData", {
                        dialog: true
                    });
                    next();
                },
                components: {
                    modal: Edit
                }
            }]
    }];
