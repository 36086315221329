import RestApi from "@/api/RestApi";

export default class Dashboard extends RestApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "dashboard/";
	}

	async totalEmployees(payload) {
		let url = this.baseEndpoint+"totalEmployees";
		let response = await this.getData(url,payload);
		return response;
	}
	async totalCost(payload) {
		let url = this.baseEndpoint+"totalCost";
		let response = await this.getData(url,payload);
		return response;
	}
	async contractTermination(payload) {
		let url = this.baseEndpoint+"contract-termination";
		let response = await this.getData(url,payload);
		return response;
	}
	async overTime(payload) {
		let url = this.baseEndpoint+"overtime";
		let response = await this.getData(url,payload);
		return response;
	}
	async sickLeave(payload) {
		let url = this.baseEndpoint+"sickLeave";
		let response = await this.getData(url,payload);
		return response;
	}

}
