import Vue from "vue";

export default class RestApi {
	constructor() {
		this.domain = process.env.VUE_APP_API_URL;
	}

	async getData(url, pagination = null) {
		let data = pagination ? pagination : "";
		const response = await Vue.axios.get(url, {
			params: data
		});
		return response.data;
	}

	async insertData(url, data,config={}) {
		const response = await Vue.axios.post(url, data,config);
		return response.data;
	}

	async updateData(url, data) {
		const response = await Vue.axios.post(url, data);
		return response.data;
	}

	async putData(url, data) {
		const response = await Vue.axios.put(url, data);
		return response.data;
	}

	async deleteData(url) {
		let response = await Vue.axios.delete(url);
		return response.data;
	}
}
