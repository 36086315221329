<template>
  <form-wizard
      :form-data.sync="formData"
      :item="item"
      :namespace="namespace"
      :title="title"
      :createFormData="true"
      :displayName="true"
      :icon="icon"
      :store="store"
      :store-action="storeAction"
  ></form-wizard>
</template>
<script>
export default {
  name: "ProductivityCreate",
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  data() {
    return {
      item: {
      },
    };
  },
  created() {
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    },
    storeAction() {
      return this.$route.meta.storeAction || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    formData() {
      let form = [
        {
          text: "File",
          value: "file",
          flex: "col-sm-12 col-md-12",
          required: "required",
          file: true,
          format:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv",
        },
        {
          text: "Select month",
          value: "date",
          flex: "col-sm-12 col-md-12",
          required: "required",
          justMonth: true
        }
      ];
      return form;
    }
  },
};
</script>
