const loading = state => state.loading;
const pagination = state => state.pagination;
const totalEmployees = state => state.totalEmployees;
const totalCost = state => state.totalCost;
const overTime = state => state.overTime;
const sickLeave = state => state.sickLeave;
const contractTermination = state => state.contractTermination;
/*const totalEmployeesCompare = state => state.totalEmployeesCompare;
const totalCostCompare = state => state.totalCostCompare;
const overTimeCompare = state => state.overTimeCompare;
const sickLeaveCompare = state => state.sickLeaveCompare;
const contractTerminationCompare = state => state.contractTerminationCompare;*/
const cardData = state => state.cardData;
const filter = state => state.filter;
const selected = state => state.selected;
const chartData = state => state.chartData;
const chartRawData = state => state.chartRawData;

export default {
	loading,
	pagination,
	totalEmployees,
	sickLeave,
	overTime,
	totalCost,
	contractTermination,
	/*totalEmployeesCompare,
	sickLeaveCompare,
	overTimeCompare,
	totalCostCompare,
	contractTerminationCompare,*/
	cardData,
	filter,
	selected,
	chartRawData,
	chartData
};
