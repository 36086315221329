<template>
  <two-tables
      :names="names"
      :icon="icon"
      :details-icon="detailsIcon"
      :title="title"
      :details-title="titleDetails"
      :route-name="routeName"
      :key-title="keyTitle"
      :namespace="namespace"
      :details-header="detailsHeader"
  ></two-tables>
</template>
<script>
export default {
  name: "ProductivityList",
  components: {
    TwoTables: () => import("@/components/core/tables/TwoTables")
  },
  data() {
    return {
      routeName: "productivity",
      namespace: "productivity",
      title: "Productivity",
      titleDetails: "Details",
      keyTitle: "name",
      names: [
        {
          text: 'Details',
          width: "5%",
          sortable: false,
        }, {
          text: "Date",
          value: "for_month",
          sortable: true,
          align:"center",
          width:"10%"
        },
        {
          text: "Vacation days used",
          value: "vacation_days_used",
          align:"center",
          width:"15%"

        }, {
          text: "Total Neto",
          value: "neto_salary_sum",
          align:"center"
        }, {
          text: "Total Gross",
          value: "gross_salary_sum",
          align:"center"
        }, {
          text: "Total Cost",
          value: "total_cost_sum",
          align:"center"
        },
      ],
      detailsIcon:"mdi-table-settings",
      detailsHeader:[
        {
          text: "Employee Code",
          value: "emp_code",
          sortable: true,
          width:"140px",
          align:"start"
        }, {
          text: "First Name",
          value: "first_name",
          width:"130px",
        }, {
          text: "Last Name",
          value: "last_name",
          width:"150px"
        },{
          text: "Job Location",
          value: "job_location",
          width:"150px"
        },{
          text: "Job Name",
          value: "job_position_name",
          width:"150px"
        },{
          text: "Contract Signed In",
          value: "first_contract_signed_at",
          width:"170px"
        },{
          text: "Contract Updated At",
          value: "contract_updated_at",
          width:"170px"
        },{
          text: "Contract Expire",
          value: "contract_expired",
          width:"150px"
        },{
          text: "Vacation Days",
          value: "vacation_days",
          width:"160px",
          align: "center"
        },{
          text: "Vacation Days Used",
          value: "vacation_days_used",
          width:"170px",
          align: "center"

        },{
          text: "Vacation Days Left",
          value: "vacation_days_left",
          width:"160px",
          align: "center"
        }, {
          text: "Sick leave hours",
          value: "sick_leave",
          width: "160px",
          align: "center"
        },
        {
          text: "Overtime hours",
          value: "overtime_hours",
          width: "150px",
          align: "center"
        }, {
          text: "Neto Salary",
          value: "neto_salary",
          width:"140px",
          align: "end"

        },{
          text: "Gross Salary",
          value: "gross_salary",
          width:"150px",
          align: "end"

        },{
          text: "Total Cost",
          value: "total_cost",
          width:"120px",
          align: "end"
        },
      ]
    }
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    }
  }

}
</script>