"use strict";

import Vue from "vue";
import axios from "axios";
import store from "@/store";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
	baseURL: process.env.VUE_APP_API_URL || "/api",
	//headers: { 'X-CSRF-TOKEN': window.csrf_token },
	// timeout: 60 * 1000, // Timeout
	//withCredentials: true,
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
	function (config) {
		store.commit("options/setOptionsData", { loading: true }, { root: true });

		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

// Add a response interceptor
_axios.interceptors.response.use(
	function (response) {
		if (
			typeof response.data.message !== "undefined" &&
			response.data.message != ""
		) {
			store.commit(
				"options/setOptionsData", { message: response.data.message, snackbar: true, color: "success" }, { root: true }
			);
		}
		store.commit("options/setOptionsData", { loading: false }, { root: true });
		return response;
	},
	function (error) {
		// Do something with response error
		if (
			error.status === 401 &&
			error.config &&
			!error.config.__isRetryRequest
		) {
			// if you ever get an unauthorized, logout the user
			store.dispatch("auth/logout");
			// you can also redirect to /login if needed !
		}
		if (
			typeof error.response.data.message !== "undefined" &&
			error.response.data.message != ""
		) {
			store.commit(
				"options/setOptionsData", { message: error.response.data.message, snackbar: true, color: "red" }, { root: true }
			);
		}

		if (
			(error?.response?.status === 404 || error?.response?.status === 500) &&
			error.response.config &&
			!error.response.config.__isRetryRequest
		) {
			// if you ever get an unauthorized, logout the user
			store.commit(
				"options/setOptionsData", { message: "Error "+error.response.status+" on our side. Please try again later", snackbar: true, color: "red" }, { root: true }
			);
			// you can also redirect to /login if needed !
		}
		store.commit("options/setOptionsData", { loading: false }, { root: true });
		return Promise.reject(error);
	}
);

Plugin.install = function (Vue, options) {
	Vue.axios = _axios;
	window.axios = _axios;
	Object.defineProperties(Vue.prototype, {
		axios: {
			get() {
				return _axios;
			}
		},
		$axios: {
			get() {
				return _axios;
			}
		}
	});
};

Vue.use(Plugin);

export default Plugin;
