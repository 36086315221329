<template>
  <v-container fluid v-if="item">
    <v-row justify="space-around" no-gutters>
      <v-col cols="12">
        <v-sheet color="grey lighten-5" class="py-12"
                 v-if="item">
          <v-row align="start" justify="end">
            <v-col cols="12" md="4" sm="12">
              <div class="d-flex">
                <v-avatar :size="$vuetify.breakpoint.mdAndDown?50:100" v-if="icon">
                  <v-icon :color="color" :size="$vuetify.breakpoint.mdAndDown?40:85">{{ icon }}</v-icon>
                </v-avatar>
                <div class="ma-2">
                  <div
                      class="grey--text text--darken-2 mb-2 display-1 d-flex text-left"
                      v-if="item.first_name"
                  >
                    {{ item.first_name }} {{ item.middle_name }} {{ item.last_name }}
                  </div
                  >
                  <div class="subtitle-1 grey--text text--darken-1  d-flex text-left"
                       v-if="item.psit_id"># {{ item.psit_id }}<br> {{
                      item.job_position_name
                    }} <br>{{ item.job_location }}
                  </div
                  >
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="4" sm=12 class="text-left">
              <v-card>
                <v-card-title class="pb-2">
                  <v-sheet color="light-blue accent-1"
                           class="overflow-hidden mt-n9 transition-swing v-card--material__sheet"
                           elevation="5"
                           rounded>
                    <div class="pa-5">
                      <v-icon large dark>mdi-clipboard-file-outline</v-icon>
                    </div>
                  </v-sheet>
                  <div class="text-h5 v-card--material__title pl-4"><span>Contract</span></div>
                  <v-spacer></v-spacer>
                  <div class="text-right" v-if="!item.deleted_at">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            large
                            v-bind="attrs"
                            v-on="on"
                            color="red"
                            v-if="userCan('employees-terminate-contract')"
                            @click="showTerminationDialog=true"
                        >
                          <v-icon>mdi-clipboard-text</v-icon>
                        </v-btn>
                      </template>
                      <span>Terminate Contract</span>
                    </v-tooltip>
                  </div>

                </v-card-title>

                <v-row no-gutters class="px-5 pb-2">
                  <v-col cols="12">
                    <v-row align="start" no-guttersv-if="item.last_contract_date">
                      <v-col class="text-overline" cols="8">Last contract date:</v-col>
                      <v-col class="text-right body-1" cols="4">{{ formatDate(item.last_contract_date) }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row align="center" no-gutters v-if="item.first_contract_date">
                      <v-col class="text-overline" cols="8">First contract date:</v-col>
                      <v-col class="text-right body-1" cols="4">{{ formatDate(item.first_contract_date) }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row align="center" no-gutters>
                      <v-col align-self="start" class="text-overline">Expire:</v-col>
                      <v-col class="text-right body-1"> {{
                          item.contract_expiration_date ?
                              formatDate(item.contract_expiration_date) : 'Indefinitely'
                        }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" sm="12" class="text-left">
              <v-card>
                <v-card-title class="pb-2">
                  <v-sheet color="blue accent-1" class="overflow-hidden mt-n9 transition-swing v-card--material__sheet"
                           elevation="5"
                           rounded>
                    <div class="pa-5">
                      <v-icon large dark>mdi-wallet-outline</v-icon>
                    </div>
                  </v-sheet>
                  <div class="text-h5 v-card--material__title pl-4"><span>Salaries</span></div>
                </v-card-title>
                <v-row no-gutters class="px-5 pb-2">
                  <v-col cols="12">
                    <v-row align="center">
                      <v-col class="text-overline" cols="8">Neto salary:</v-col>
                      <v-col class="text-right body-1" cols="4">{{ item.neto_salary.toFixed(2) }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row align="center">
                      <v-col class="text-overline" cols="8">Bruto salary:</v-col>
                      <v-col class="text-right body-1" cols="4">{{ item.gross_salary.toFixed(2) }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row align="center">
                      <v-col align-self="start" class="text-overline">Total expense:</v-col>
                      <v-col class="text-right body-1"> {{
                          item.total_cost.toFixed(2)
                        }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="12">
        <v-tabs
            v-model="tab"
            :background-color="color"
            :active-class="'grey lighten-5 ' + textColor"
            grow
            dark
            icons-and-text
        >
          <v-tabs-slider color="transparent"></v-tabs-slider>

          <v-tab href="#tab-1" v-if="userCan('employees-show-productivity')">
            Productivity
            <v-icon>mdi-finance</v-icon>
          </v-tab>
          <v-tab href="#tab-2" v-if="userCan('employees-show-comments')">
            Comments
            <v-badge
                :content="item.comments_count"
                :value="item.comments_count"
                overlap
            >
              <v-icon>mdi-comment-text-multiple</v-icon>
            </v-badge>
          </v-tab>
          <v-tab href="#tab-3" v-if="item.terminated_contracts.length">
            Terminated contracts
            <v-icon>mdi-clipboard-text</v-icon>
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item :value="'tab-1'" v-if="userCan('employees-show-productivity')">
            <productivity-tab :item="item" :color="color"></productivity-tab>
          </v-tab-item>
          <v-tab-item :value="'tab-2'" v-if="userCan('employees-show-comments')">
            <comment-list type="employees" :typeId="id"></comment-list>
          </v-tab-item>
          <v-tab-item :value="'tab-3'" v-if="item.terminated_contracts.length">
            <terminated-contracts :contracts="item.terminated_contracts"></terminated-contracts>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <terminate-contract-dialog
        v-if="showTerminationDialog"
        :color="color"
        :employee-id.sync="id"
        :go-to-url="goTo"
        :dialog.sync="showTerminationDialog"
        @dialog-cancel="updateTerminationDialog(false)"
        @dialog-update="updateTerminationDialog"
    ></terminate-contract-dialog>
  </v-container>
</template>
<script>
import date from "@/mixins/date";
import permission from "@/mixins/permission";
import Vue from "vue";

export default {
  name: "EmployeeShow",
  mixins: [date, permission],
  components: {
    ProductivityTab: () => import("@/components/employees/tabs/ProductivityTab"),
    CommentList: () => import("@/components/comments/List"),
    TerminateContractDialog: () => import("@/components/core/dialogs/TerminateContract"),
    TerminatedContracts: () => import("@/components/employees/tabs/TerminatedContracts")
  },
  data() {
    return {
      item: null,
      routeName: "employee",
      tab: null,
      showTerminationDialog: false
    };
  },
  created() {
    this.getData()
  },
  computed: {
    goTo() {
      return "/employee/show/" + this.item.id;
    },
    icon() {
      return this.$route.meta.icon || "";
    },
    id() {
      return this.$route.params.id || null;
    },
    textColor() {
      return this.$route.meta.textColor || "";
    },
    color() {
      return this.$route.meta.color || "";
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },

  },
  methods: {
    async getData() {
      await this.$store.dispatch("employees/showItem", this.id).then(() => {
        this.item = this.$store.getters[this.store + "/item"];
      });
    },
    updateTerminationDialog(dialog) {
      this.showTerminationDialog = dialog;
      this.getData();
    },
  }
};
</script>
<style scoped>
>>> .theme--dark.v-label {
  color: rgb(255, 255, 255);
}
</style>
