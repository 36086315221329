import View from "@/views/Permission.vue";
import Index from "@/components/permissions/List.vue";
import Create from "@/components/permissions/Create.vue";
import Edit from "@/components/permissions/Edit.vue";
import store from "@/store";

export default [
	{
		path: "/permission",
		name: "permission",
		component: View,
		title: "Permission",
		redirect: {
			name: "list-permission"
		},
		children: [
			{
				path: "list",
				name: "list-permission",
				meta: {
					icon: "mdi-account-check"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: false
					});
					next();
				},
				components: {
					page: Index
				}
			},
			{
				path: "create",
				name: "users-permissions-create",
				meta: {
					storeAction: "permissions/insertItem",
					namespace: "permission",
					store: "permissions",
					title: "Create Permission",
					icon: "mdi-account-check"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Create
				}
			},
			{
				path: "edit/:id",
				name: "users-permissions-edit",
				meta: {
					storeAction: "permissions/updateItem",
					namespace: "permission",
					store: "permissions",
					title: "Edit permission",
					icon: "mdi-account-check"
				},
				beforeEnter: (to, from, next) => {
					store.commit("options/setOptionsData", {
						dialog: true
					});
					next();
				},
				components: {
					modal: Edit
				}
			}
		]
	}
];
