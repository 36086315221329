import Vue from "vue";
import Vuex from "vuex";
import options from "./options/index";
import users from "./users/index";
import dashboard from "./dashboard/index";
import permissions from "./permissions/index";
import comments from "./comments/index";
import fileTypes from "./fileTypes/index";
import roles from "./roles/index";
import employees from "./employees/index";
import notifications from "./notifications/index";
import files from "./files/index";
import logs from "./logs/index";
import accessLogs from "./accessLogs/index";
import productivity from "./productivity/index";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        options,
        users,
        permissions,
        notifications,
        dashboard,
        roles,
        files,
        comments,
        logs,
        employees,
        accessLogs,
        fileTypes,
        productivity
    }
});
