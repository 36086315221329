import DashboardApi from "@/api/dashboard";
import ErrorHandling from "@/plugins/ErrorHandling";
import moment from "moment";

const errorHandling = new ErrorHandling();
const restApi = new DashboardApi();

const setPagination = async ({commit}, payload) => {
    commit("setPagination", payload);
};
/*const setCompareValues = async ({commit,state}) => {
    try {
        commit("setLoading", true);
        const options = JSON.parse(JSON.stringify(state.filter));
        options["date_from"] =  moment(options["date_from"]).subtract(1, 'years').format("YYYY-MM");
        options["date_to"] =  moment(options["date_to"]).isValid()? moment(options["date_to"]).subtract(1, 'years').format("YYYY-MM") :moment().subtract(1, 'years').format("YYYY-MM");

        const itemsData = await restApi.totalEmployees(options);
        commit("setItemsDataCompare", itemsData);
        const costCompare = await restApi.totalCost(options);
        commit("setTotalCostCompare", costCompare);
        const contractCompare = await restApi.contractTermination(options);
        commit("setContractTerminationCompare", contractCompare);
        const overtimeCompare = await restApi.overTime(options);
        commit("setOverTimeCompare", overtimeCompare);
        const sickCompare = await restApi.sickLeave(options);
        commit("setSickLeaveCompare", sickCompare);
        commit("setTotalsCompare");
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error)
    }
}*/
const totalEmployees = async ({commit,state}, payload) => {
    try {
        commit("setLoading", true);
        const items = await restApi.totalEmployees(state.filter);
        commit("setItemsData", items);
        if(payload){
        commit("setTotals");
        }
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error)
    }
}
const totalCost = async ({commit,state}, payload) => {
    try {
        commit("setLoading", true);
        const items = await restApi.totalCost(state.filter);
        commit("setTotalCost", items);
        if(payload){
        commit("setTotals");
        }
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error)
    }
}
const contractTermination = async ({commit,state}, payload) => {
    try {
        commit("setLoading", true);
        const items = await restApi.contractTermination(state.filter);
        commit("setContractTermination", items);
        if(payload){
        commit("setTotals");
        }
        commit("setLoading", false);
    } catch (error) {
        errorHandling.onError(error)
    }
}
const overTime = async ({commit,state}, payload) => {
    try {
        commit("setLoading", true);
        const items = await restApi.overTime(state.filter);
        commit("setOverTime", items);
        if(payload){
        commit("setTotals");
        }
		commit("setLoading", false);
	} catch (error) {
        errorHandling.onError(error)
    }
}
const sickLeave = async ({commit,state}, payload) => {
    try {
        commit("setLoading", true);
        const items = await restApi.sickLeave(state.filter);
        commit("setSickLeave", items);
        if(payload){
        commit("setTotals");
        }
		commit("setLoading", false);
	} catch (error) {
        errorHandling.onError(error)
    }
}

export default {
    setPagination,
    //setCompareValues,
    totalEmployees,
    totalCost,
    overTime,
    sickLeave,
    contractTermination
};
