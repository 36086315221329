import Vue from "vue";
import _ from "lodash";
const setItemsData = (state, value) => {
	state.items = value.data ? value.data : value;
	state.total = value.total;
};
const setNewNotifications = (state, value) => {
	state.newNotifications = value.data ? value.data : value;
	state.newNotificationsBadge = _.size(value.data);
};

const addNewNotification = (state, value) => {
	state.newNotificationsBadge = state.newNotificationsBadge + 1;
};

const removeNewNotification = (state, value) => {
	state.newNotificationsBadge = state.newNotificationsBadge - 1;
};

const setLoading = (state, value) => {
	state.loading = value;
};

const setLoadingNew = (state, value) => {
	state.loadingNew = value;
};

const setPagination = (state, value) => {
	Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

const snackOptionsData = (state, payload)=> {
	Vue.set(state, 'snackOptions', Object.assign(state.snackOptions, payload));
	//state.options = Object.assign(state[payload.name], payload)
};

export default {
	setNewNotifications,
	setLoadingNew,
	setItemsData,
	addNewNotification,
	removeNewNotification,
	setPagination,
	snackOptionsData,
	setLoading
};
