import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
	items: [],
	available: [],
	terminationReasons: [],
	activityLog: {},
	total: 0,
	totalDetails: 0,
	item: {},
	loading: false,
	expiredContracts:[],
	productivityDetails:[],
	sevenDaysContracts:[],
	jobPositions:[],
	jobLocations:[],
	exportItems:[],
	pagination: {
		page: 1,
		orderBy: "id",
		mustSort: true,
		descending: true,
		sortDesc: false,
		search: "",
		rowsPerPage: 12,
		loadRows: 12,
		jobPositionNames:[],
		jobLocations:[],
		terminatedContract:[],
		terminatedContractTypeId:[],
		salaryFrom: null,
		salaryTo:null,
		salaryType:null,
		//contract
		contractLength:null,
		//vacation days
		vacationType:null,
		vacationFrom:null,
		vacationTo:null,
		sickLeaveFrom:null,
		sickLeaveTo:null,
		overtimeHoursFrom:null,
		indefiniteContract:null,
		contractTermination:null,
		overtimeHoursTo:null
	},
	filtersShowPage:[
		{
			fieldType:"FormCombobox",
			label:"Job position",
			name:"Job position",
			modelName:"jobPositionNames",
			loadExternaly:true,
			multiple:true,
			loadingGetter:'employees/loading',
			dataDispatch:'employees/jobPositions',
			dataGetter:'employees/jobPositions',
			class:"col-sm-6 col-md-6 col-12",
			clearable:true,
		},
		{
			fieldType:"FormCombobox",
			label:"Job location",
			name:"Job location",
			modelName:"jobLocations",
			loadExternaly:true,
			multiple:true,
			loadingGetter:'employees/loading',
			dataDispatch:'employees/jobLocations',
			dataGetter:'employees/jobLocations',
			class:"col-sm-6 col-md-6 col-12",
			clearable:true,
		},
		{
			fieldType:"FormAutocomplete",
			label:"Contract Type",
			name:"Contract Type",
			modelName:"contractLength",
			multiple:false,
			class:"col-sm-4 col-md-4 col-4",
			clearable:true,
			options: [{
				text: "Definite",
				value: "definite",
			},
			{
				text: "Indefinite",
				value: "indefinite",
			},
			]
		},
		{
			fieldType:"FormAutocomplete",
			label:"Salaries",
			name:"Salaries",
			modelName:"salaryType",
			class:"col-sm-4 col-md-4 col-4",
			clearable:true,
			options: [{
				text: "Neto salary",
				value: "neto",
			},
			{
				text: "Gross salary",
				value: "gross",
			},
			{
				text: "Total cost",
				value: "total",
			}]
		},
		{
			fieldType:"FormTextField",
			label:"Salary from",
			name:"Salary from",
			modelName:"salaryFrom",
			class:"col-sm-2 col-md-2 col-2",
			clearable:true,
			sufix:'$',
			inputType:'number',
			hiddenIfMissing:true,
			hiddenIfMissingModel:"salaryType"
		},
		{
			fieldType:"FormTextField",
			label:"Salary to",
			name:"Salary to",
			modelName:"salaryTo",
			class:"col-sm-2 col-md-2 col-2",
			clearable:true,
			sufix:'$',
			inputType:'number',
			hiddenIfMissing:true,
			hiddenIfMissingModel:"salaryType"
		},
		
		// {
		// 	fieldType:"FormDate",
		// 	label:"Date from",
		// 	name:"Date from",
		// 	modelName:"contractFrom",
		// 	class:"col-sm-4 col-md-3 col-12",
		// 	clearable:true,
		// 	hiddenIfMissing:true,
		// 	hiddenIfMissingModel:"contractType"
		// },
		// {
		// 	fieldType:"FormDate",
		// 	label:"Date to",
		// 	name:"Date to",
		// 	modelName:"contractTo",
		// 	class:"col-sm-4 col-md-3 col-12",
		// 	clearable:true,
		// 	hiddenIfMissing:true,
		// 	hiddenIfMissingModel:"contractType"
		// },
		{
			fieldType:"FormAutocomplete",
			label:"Vacation Days",
			name:"Vacation Days",
			modelName:"vacationType",
			class:"col-sm-4 col-md-4 col-12",
			clearable:true,
			options: [{
				text: "Vacation Days",
				value: "vacationDays",
			},
			{
				text: "Vacation Days Used",
				value: "vacationDaysUsed",
			},
			{
				text: "Vacation Days Left",
				value: "vacationDaysLeft",
			}]
		},
		{
			fieldType:"FormTextField",
			label:"Days From",
			name:"Days From",
			modelName:"vacationFrom",
			class:"col-sm-4 col-md-3 col-12",
			clearable:true,
			inputType:'number',
			hiddenIfMissing:true,
			hiddenIfMissingModel:"vacationType"
		},
		{
			fieldType:"FormTextField",
			label:"Days To",
			name:"Days To",
			modelName:"vacationTo",
			class:"col-sm-4 col-md-3 col-12",
			clearable:true,
			inputType:'number',
			hiddenIfMissing:true,
			hiddenIfMissingModel:"vacationType"
		},
		{
			fieldType:"FormLabel",
			label:"Sick leave",
			class:"col-sm-6 col-md-4 col-12 text-left font-weight-black",
		},
		{
			fieldType:"FormTextField",
			label:"Sick leave from",
			name:"Sick leave from",
			modelName:"sickLeaveFrom",
			class:"col-sm-4 col-md-3 col-12",
			clearable:true,
			sufix:'h',
			inputType:'number',
		},
		{
			fieldType:"FormTextField",
			label:"Sick leave to",
			name:"Sick leave to",
			modelName:"sickLeaveTo",
			class:"col-sm-4 col-md-3 col-12",
			clearable:true,
			sufix:'h',
			inputType:'number',
		},
		{
			fieldType:"FormLabel",
			label:"Overtime hours",
			class:"col-sm-6 col-md-4 col-12 text-left font-weight-black",
		},
		{
			fieldType:"FormTextField",
			label:"Overtime hours from",
			name:"Overtime hours from",
			modelName:"overtimeHoursFrom",
			class:"col-sm-4 col-md-3 col-12",
			clearable:true,
			sufix:'h',
			inputType:'number',
		},
		{
			fieldType:"FormTextField",
			label:"Overtime hours to",
			name:"Overtime hours to",
			modelName:"overtimeHoursTo",
			class:"col-sm-4 col-md-3 col-12",
			clearable:true,
			sufix:'h',
			inputType:'number',
		},
	],
	filtersIndex:[
		{
			fieldType:"FormCombobox",
			label:"Job position",
			name:"Job position",
			modelName:"jobPositionNames",
			loadExternaly:true,
			multiple:true,
			loadingGetter:'employees/loading',
			dataDispatch:'employees/jobPositions',
			dataGetter:'employees/jobPositions',
			class:"col-sm-6 col-md-6 col-12",
			clearable:true,
		},
		{
			fieldType:"FormCombobox",
			label:"Job location",
			name:"Job location",
			modelName:"jobLocations",
			loadExternaly:true,
			multiple:true,
			loadingGetter:'employees/loading',
			dataDispatch:'employees/jobLocations',
			dataGetter:'employees/jobLocations',
			class:"col-sm-6 col-md-6 col-12",
			clearable:true,
		},
		{
			fieldType:"FormAutocomplete",
			label:"Contract Type",
			name:"Contract Type",
			modelName:"contractLength",
			multiple:false,
			class:"col-sm-4 col-md-4 col-4",
			clearable:true,
			options: [{
				text: "Definite",
				value: "definite",
			},
			{
				text: "Indefinite",
				value: "indefinite",
			},
			]
		},
		{
			fieldType:"FormAutocomplete",
			label:"Salaries",
			name:"Salaries",
			modelName:"salaryType",
			class:"col-sm-4 col-md-4 col-4",
			clearable:true,
			options: [{
				text: "Neto salary",
				value: "neto",
			},
			{
				text: "Gross salary",
				value: "gross",
			},
			{
				text: "Total cost",
				value: "total",
			}]
		},
		{
			fieldType:"FormTextField",
			label:"Salary from",
			name:"Salary from",
			modelName:"salaryFrom",
			class:"col-sm-2 col-md-2 col-2",
			clearable:true,
			sufix:'$',
			inputType:'number',
			hiddenIfMissing:true,
			hiddenIfMissingModel:"salaryType"
		},
		{
			fieldType:"FormTextField",
			label:"Salary to",
			name:"Salary to",
			modelName:"salaryTo",
			class:"col-sm-2 col-md-2 col-2",
			clearable:true,
			sufix:'$',
			inputType:'number',
			hiddenIfMissing:true,
			hiddenIfMissingModel:"salaryType"
		},
		
		// {
		// 	fieldType:"FormDate",
		// 	label:"Date from",
		// 	name:"Date from",
		// 	modelName:"contractFrom",
		// 	class:"col-sm-4 col-md-3 col-12",
		// 	clearable:true,
		// 	hiddenIfMissing:true,
		// 	hiddenIfValue:'firstDate',
		// 	hiddenIfMissingModel:"contractType"
		// },
		// {
		// 	fieldType:"FormDate",
		// 	label:"Date to",
		// 	name:"Date to",
		// 	modelName:"contractTo",
		// 	class:"col-sm-4 col-md-3 col-12",
		// 	clearable:true,
		// 	hiddenIfMissing:true,
		// 	hiddenIfValue:'firstDate',
		// 	hiddenIfMissingModel:'firstDate',
		// },
		// {
		// 	fieldType:"FormCheckbox",
		// 	label:"Include indefinitely",
		// 	name:"Include indefinitely",
		// 	modelName:"indefiniteContract",
		// 	class:"col-sm-4 col-md-2 col-12",
		// 	hiddenIfMissing:true,
		// 	hiddenIfValue:"expirationDate",
		// 	hiddenIfMissingModel:"contractType"
		// },
		{
			fieldType:"FormLabel",
			label:"Employees with terminated contract:",
			class:"col-sm-6 col-md-4 col-12 text-left font-weight-black",
		},
		{
			fieldType:"FormRadioGroup",
			name:"Include indefinitely",
			modelName:"terminatedContract",
			class:"col-sm-12 col-md-5 col-12",
			options: [{
				text: "Do not show",
				value: "dont_show",
			},
			{
				text: "Include",
				value: "include",
			},
			{
				text: "Only terminated",
				value: "only",
			}]
		},
		{
			fieldType:"FormAutocomplete",
			label:"Termination reasons",
			name:"Termination reasons",
			modelName:"terminatedContractTypeId",
			loadExternaly:true,
			hiddenIfMissing:true,
			hiddenIfMissingModel:'terminatedContract',
			hiddenIfValue:['include','only'],
			multiple:true,
			text: "display_name",
			value: "id",
			loadingGetter:'employees/loading',
			dataDispatch:'employees/terminationReasons',
			dataGetter:'employees/terminationReasons',
			class:"col-sm-12 col-md-3 col-12",
			clearable:true,
		},
	],
};

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations
};
