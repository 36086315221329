const items = state => state.items;
const available = state => state.available;
const terminationReasons = state => state.terminationReasons;
const total = state => state.total;
const totalDetails = state => state.totalDetails;
const item = state => state.item;
const loading = state => state.loading;
const pagination = state => state.pagination;
const activityLog = state => state.activityLog;
const expiredContracts = state => state.expiredContracts;
const jobLocations = state => state.jobLocations;
const jobPositions = state => state.jobPositions;
const sevenDaysContracts = state => state.sevenDaysContracts;
const exportItems = state => state.exportItems;
const filtersShowPage = state => state.filtersShowPage;
const filtersIndex = state => state.filtersIndex;
const productivityDetails = state => state.productivityDetails;
export default {
    items,
    total,
    totalDetails,
    available,
    activityLog,
    terminationReasons,
    item,
    loading,
    pagination,
    productivityDetails,
    jobLocations,
    jobPositions,
    expiredContracts,
    sevenDaysContracts,
    exportItems,
    filtersShowPage,
    filtersIndex
};
