<template>
  <simple-table
    :names="names"
    :icon="icon"
    :title="title"
    :route-name="routeName"
    :key-title="keyTitle"
    :namespace="namespace"
  ></simple-table>
</template>
<script>
export default {
  name: "AccessLogsList",
  components: {
    SimpleTable: () => import("@/components/core/tables/SimpleTable")
  },
  data() {
    return {
      routeName: "accessLog",
      namespace: "accessLogs",
      title: "Access Logs",
      keyTitle: "level",
      names: [
        {
          text: "User",
          value: "user.name",
          sortable: false
        },
        {
          text: "IP address",
          value: "ip_address",
          sortable: false
        },
        {
          text: "Accessed at",
          value: "accessed_at",
          sortable: false
        }
      ]
    };
  },
  computed: {
    icon() {
      return this.$route.meta.icon || "";
    }
  },
  created() {}
};
</script>
